import { cn } from "bits/utils";
import Image from "next/image";
import type {
  DatabaseRecord,
  DirectoryContentType,
  DirectoryItemType,
} from "publit-shared";

interface ProfileImageProps {
  item: DatabaseRecord<DirectoryItemType>;
  size: "small" | "medium" | "large" | "full";
}

export function ProfileImage({ item, size }: ProfileImageProps) {
  const classes = cn("block bg-gray-300 shrink-0", {
    "rounded-lg w-[50px] h-[50px]": size === "small",
    "rounded-lg w-[100px] h-[100px]": size === "medium",
    "rounded-lg w-[200px] h-[200px]": size === "large",
    "w-full h-full": size === "full",
  });

  const pxSize = size === "small" ? 50 : size === "medium" ? 100 : 200;

  return item.data.profileImage?.imageURL ? (
    <Image
      alt={item.data.name ?? ""}
      className={classes}
      src={item.data.profileImage.imageURL}
      width={pxSize}
      height={pxSize}
      priority={size === "large"}
    />
  ) : (
    <div className={classes} />
  );
}

interface LogoImageProps {
  directory: DirectoryContentType;
  size: "small" | "medium";
  className?: string;
}

export function LogoImage({ directory, size, className }: LogoImageProps) {
  const classes = cn(
    "block",
    {
      "w-[50px] h-[50px]": size === "small",
      "w-[100px] h-[100px]": size === "medium",
    },
    className,
  );

  const pxSize = size === "small" ? 50 : size === "medium" ? 100 : 200;

  return directory.info.logo?.imageURL ? (
    <Image
      alt={directory.info.title ?? ""}
      className={classes}
      src={directory.info.logo.imageURL}
      width={pxSize}
      height={pxSize}
      priority={size === "medium"}
    />
  ) : null;
}

export function CoverImage({ directory }: { directory: DirectoryContentType }) {
  return directory.info.coverImage?.imageURL ? (
    <Image
      alt={directory.info.title ?? ""}
      src={directory.info.coverImage.imageURL}
      width={1488}
      height={837}
      priority
    />
  ) : null;
}
