"use client";

import { useTrack } from "lib/analytics/useTrack";
import type { ContentModeType } from "publit-shared";

export default function Analytics({
  mode,
  itemID,
}: {
  mode: ContentModeType;
  itemID?: string;
}) {
  useTrack({ isPreview: mode === "live-preview", itemID });

  return <div className="hidden" hidden data-analytics />;
}
